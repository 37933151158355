<template>
  <div :class="$style.component" :style="style">
    <base-image :image="item.image" />
  </div>
</template>

<script>
import BaseImage from '@/components/base-image'

export default {
  components: {
    BaseImage
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    style() {
      return {
        'padding-bottom': this.paddingBottom
      }
    },
    paddingBottom() {
      return (100 / this.item.image.ratio).toFixed(2) + '%'
    }
  }
}
</script>

<style lang="scss" module>
// .component {
//   &:not(:last-of-type) {
//     padding-bottom: calc(var(--blank-line) * 2);
//   }

//   @media (min-width: $medium) {
//     &:not(:last-of-type) {
//       padding-bottom: 0;
//     }
//   }
// }

.component {
  position: relative;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
